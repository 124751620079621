export default ({ store }) => {
  return {
    fields: {
      displayName: {
        name: "displayName",
        type: "text",
        label: "ls.founderBoard.fields.displayName",
      },
      aboutContent: {
        name: "aboutContent",
        type: "editor",
        label: "ls.founderBoard.fields.aboutContent",
        legend: "ls.founderBoard.fields.aboutContentLegend",
      },
      footerTagLine: {
        name: "footerTagLine",
        type: "text",
        label: "ls.founderBoard.fields.footerTagLine",
      },
      logos: {
        name: "logos",
        type: "repeatable",
        label: "ls.founderBoard.fields.logos.label",
        translatable: true,
        fields: {
          link: {
            name: "link",
            label: "ls.founderBoard.fields.logos.linkLabel",
            type: "text",
          },
          alt: {
            name: "alt",
            label: "ls.founderBoard.fields.logos.altLabel",
            type: "text",
            translatable: true,
          },
          image: {
            name: "image",
            label: "ls.founderBoard.fields.logos.imageLabel",
            type: "mediaUrl",
            translatable: true,
          },
        },
      },
      /*
      themeSettings: {
        name: "themeSettings",
        type: "weThemeSettings",
        label: "ls.founderBoard.fields.themeSettings",
        secret: {
          schema: {
            type: DataTypes.JSON,
            default: {},
            defaultValue: {},
          }
        }
      },
       */
      /*

      mainImageUrl: {
        name: "mainImageUrl",
        label: "ls.founderBoard.fields.mainImage",
        type: "mediaUrl",
        secret: {
          schema: {
            type: DataTypes.STRING,
          }
        }
      }
      */
    },
  };
};
