import { defineAsyncComponent } from "vue";

export default function (path, withDefineAsyncComponent = false) {
  let $overridePath = `@/overrides/client/${path}`; //`${$overridePath}`

  let promiseFactory = () => {
    return new Promise(async (resolve, reject) => {
      let result = false;

      if (!path) {
        return false;
      }

      let overridePath = `@/overrides/client/${path}`; //`${overridePath}`

      try {
        result = await import(
          /* webpackPrefetch: true */ `@/client/applications/${process.env.VUE_APP_APPLICATION_NAME}/${path}`
        ).catch((e) => {
          return import(/* webpackPrefetch: true */ `${overridePath}`).catch(
            (e) => {
              return import(/* webpackPrefetch: true */ `@/client/${path}`);
            }
          );
        });
      } catch (e) {
        result = false;
      }

      // result = import(/* webpackPrefetch: true */ `@/client/${path}`);
      return resolve(result);
    });
  };

  if (withDefineAsyncComponent) {
    return defineAsyncComponent(promiseFactory);
  } else {
    return promiseFactory;
  }
}
