export default ({ store }) => {
  return {
    fields: {
      title: {
        name: "title",
        type: "textarea",
        label: "ls.founderMedia.fields.title",
        maxCharacters: 200,
        validation: {
          maxLength: {
            params: {
              max: 200,
            },
          },
        },
      },
      featured: {
        name: "featured",
        type: "checkbox",
        label: "ls.founderMedia.fields.featured",
      },
    },
  };
};
