export default async ({ asyncOps, store }) => {
  return {
    dashboard: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin" },
        },
      },
      icon: "dashboard",
      label: "admin.sideNav.dashboard",
    },
    foundersTitle: {
      action: {
        type: "title",
      },
      label: "ls.adminSideNav.foundersTitle",
    },
    foundersParent: {
      action: false,
      icon: "grid-alt",
      label: "ls.adminSideNav.foundersParent",
      children: {
        founderBoards: {
          icon: "grid-alt-reverse",
          label: "ls.adminSideNav.founderBoards",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "admin-founder-board",
              },
            },
          },
        },
        founders: {
          icon: "users",
          label: "ls.adminSideNav.founders",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "admin-entity-entityType",
                params: { entityType: "founder" },
              },
            },
          },
        },
        founderMedia: {
          icon: "image",
          label: "ls.adminSideNav.founderMedia",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "admin-entity-entityType",
                params: { entityType: "founderMedia" },
              },
            },
          },
        },


      },
    },
    generalTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.generalTitle",
    },
    entities: {
      action: false,
      icon: "layer-stack",
      label: "admin.sideNav.entities",
      children: {
        entityDashboard: {
          action: {
            type: "routerNavigation",
            data: {
              to: { name: "entity-dashboard" },
            },
          },
          icon: "layer-stack",
          label: "admin.sideNav.entityDashboard",
        },
        category: {
          icon: "handle-horizontal",
          label: "admin.sideNav.category",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "admin-entity-entityType",
                params: { entityType: "category" },
              },
            },
          },
        },
        tag: {
          icon: "hashtag",
          label: "admin.sideNav.tag",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "admin-entity-entityType",
                params: { entityType: "tag" },
              },
            },
          },
        },
        article: {
          icon: "pencil-write",
          label: "admin.sideNav.article",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "admin-entity-entityType",
                params: { entityType: "article" },
              },
            },
          },
        },
      },
    },
    media: {
      label: "admin.sideNav.media",
      icon: "image",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "admin-media",
          },
        },
      },
    },
    userTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.userTitle",
    },
    user: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-user" },
        },
      },
      icon: "user",
      label: "admin.sideNav.user",
    },
    acl: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-acl" },
        },
      },
      icon: "lock",
      label: "admin.sideNav.acl",
    },
    settingsTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.settingsTitle",
    },
    language: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-language" },
        },
      },
      icon: "chat-message-alt",
      label: "admin.sideNav.language",
    },
    settings: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-config" },
        },
      },
      icon: "settings",
      label: "admin.sideNav.config",
    },
  };
};
