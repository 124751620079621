// TODO: get rid of this coupling
import useRecaptcha from "@/client/extensions/composition/useRecaptcha.js";

import {
  ref,
  unref,
  reactive,
  computed,
  watchEffect,
  nextTick,
  inject,
  getCurrentInstance,
  onBeforeUnmount,
} from "vue";

const _ = require("lodash");

/*

 // loading ui support
 // loading component - spinner - local or fixed
 // loading component - skeleton - bars, circles, squaers & prefixes (ie circle + lines etc)
 // loading component - progress bar
 */
async function getRequestAdapter(asyncPropOrName) {
  let finalName = null;

  if (typeof asyncPropOrName === "string") {
    finalName = asyncPropOrName;
  }

  if (typeof asyncPropOrName === "object" && asyncPropOrName !== null) {
    finalName = asyncPropOrName.requestAdapter || false;
  }

  if (!finalName || finalName === "default" || finalName === "Default") {
    finalName = config.asyncData.defaultRequestAdapter;
  }

  let adapter = await import(
    /* webpackChunkName: "requestAdapter" */ `@/client/extensions/composition/asyncOperations/requestAdapters/${finalName}`
  );
  return await adapter.default();
}

async function getResponseAdapter(asyncPropOrName) {
  let finalName = null;

  if (typeof asyncPropOrName === "string") {
    finalName = asyncPropOrName;
  }

  if (typeof asyncPropOrName === "object" && asyncPropOrName !== null) {
    finalName = asyncPropOrName.responseAdapter || false;
  }

  if (!finalName || finalName === "default" || finalName === "Default") {
    finalName = config.asyncData.defaultResponseAdapter;
  }

  let adapter = await import(
    /* webpackChunkName: "responseAdapter" */ `@/client/extensions/composition/asyncOperations/responseAdapters/${finalName}`
  );
  return await adapter.default();
}

import { useStore } from "vuex";
// todo: response adapters

// TODO: SSR:  this is a problem. this wont work with SSR, but we dont want to inject store everytime we use this
// solution: ?
//let mainStoreInstance = false;

// TODO: SSR  this is a problem. the global needs to sit somewhere ON THE APP  - module-wide things are ok for client but not for SSR
//solution: do not update loading instances in SSR. its a "live-UI" thing
const globalLoadingInstances = reactive({});

const socketsComposition = (props, incomingOptions = {}) => {
  let saffronSocketSymbol = Symbol("saffron-socket-symbol");
  let store = false;
  let saffronGlobal = false;
  let socketRootUrl = process.env.VUE_APP_SOCKET_ROOT_URL;
  let options;

  // setup methods
  let populateAndHandleSafeOptions = () => {
    options =
      incomingOptions && typeof incomingOptions === "object"
        ? incomingOptions
        : {};
    if (options.url && typeof options.url === "string") {
      socketRootUrl = options.url;
    }

    return true;
  };

  let populateSaffronGlobal = () => {
    if (getCurrentInstance()) {
      // inject sockets to global app state
      saffronGlobal = inject(config.saffronAppGlobalKey);
    }

    // override with options saffron global if given
    if (options.saffronGlobal) {
      saffronGlobal = options.saffronGlobal;
    }

    // at the moment, we can not continue. because socket inflation may occur as we can not cache the sockets
    if (!saffronGlobal) {
      return false; //TODO: allow access to sockets anyway?
    }

    // create global sockets in saffron, if missing
    if (!saffronGlobal.sockets) {
      saffronGlobal.sockets = {};
    }

    // create socket queue
    if (!saffronGlobal.socketCreationQueue) {
      saffronGlobal.socketCreationQueue = {};
    }

    return true;
  };

  let populateStore = () => {
    if (getCurrentInstance()) {
      // inject sockets to global app state
      store = useStore();
    }

    // options override: store
    if (options.store) {
      store = options.store;
    }

    return true;
  };

  let setup = () => {
    populateAndHandleSafeOptions();
    populateStore();
    return populateSaffronGlobal(); // only this can "fail"
  };

  // do not provide anything if setup fails. this will only happen if we are not in a component setup AND did not provide saffronGlobal & store in options
  if (!setup()) {
    // at the moment, we require saffron global to operate. otherwise - we can not track the sockets in one singleton (and probably cant set auth to them)
    return false;
  }

  // socket management methods
  const isSocketPendingCreate = (name) => {
    return saffronGlobal.socketCreationQueue.hasOwnProperty(name);
  };

  const hasSocket = (name) => {
    return saffronGlobal.sockets.hasOwnProperty(name);
  };

  const createDefaultSocket = async () => {
    return createSocket("default", {});
  };

  const lazyCreateDefaultSocket = async () => {
    if (hasSocket("default")) {
      return saffronGlobal.sockets.default;
    }

    if (isSocketPendingCreate("default")) {
      // promise from the queue
      return saffronGlobal.socketCreationQueue.default;
    }

    return createDefaultSocket();
  };

  // method to get a socket proxy, overloaded with out good stuff
  const getSocketProxy = (socket, name) => {
    // overload  socket with our stuff
    socket[saffronSocketSymbol] = reactive({
      uuid: ref(socket.id), // initial id - this is unique, and corresponds to a socket in the backend, but may correspond to an old close socket in the backend, which we do not want
      hasUuid: false,
      name: name,
      socketName: name,
      waitForUUID: () => {
        if (socket[saffronSocketSymbol].uuid) {
          return socket[saffronSocketSymbol].uuid;
        }
        return new Promise((resolve, reject) => {
          watchEffect(() => {
            if (socket[saffronSocketSymbol].uuid) {
              resolve(socket[saffronSocketSymbol].uuid);
            }
          });
        });
      },
    });

    const socketProxyHandler = {
      get(target, prop, receiver) {
        // set uuid
        if (prop === "setUUID") {
          // set uuid
          return function (uuid) {
            socket[saffronSocketSymbol].uuid = ref(uuid);
            socket[saffronSocketSymbol].hasUuid = true;
            return receiver;
          };
        }

        if (prop === "socketUUID") {
          return socket[saffronSocketSymbol].socketUUID;
        }

        // saffron data overload
        if (socket[saffronSocketSymbol].hasOwnProperty(prop)) {
          return socket[saffronSocketSymbol][prop];
        }

        // original object behaviour
        if (target.hasOwnProperty(prop)) {
          return target[prop];
        }

        return target[prop];
      },
    };

    return new Proxy(socket, socketProxyHandler);
  };

  // todo: clean this up
  const createSocket = async (nameInput = "default", options = {}) => {
    let socketConfig = {};
    let socketUrl = socketRootUrl;
    let name =
      nameInput && typeof nameInput === "string" ? nameInput : "default";
    let namespace = "";

    let implementOptions = () => {
      // default options
      if (typeof options !== "object" || !options) {
        options = {};
      }

      // options token override
      if (options.token) {
        socketConfig.auth = {
          token: options.token,
        };
      }

      // options url override
      if (options.url) {
        socketUrl = options.url;
      }

      if (options.namespace && typeof options.namespace === "string") {
        namespace = options.namespace;
      }
    };

    // automatically provide token through store, if possible

    if (store) {
      if (!store.getters["user/token"]) {
        await store.dispatch("user/refreshJwt");
      }
      socketConfig.auth = {
        token: store.getters["user/token"],
      };
    }

    // overrides store and url, etc
    implementOptions();

    // do not provide existing sockets as if they were created
    if (hasSocket(name)) {
      debug("can not create socket with this name, already exists.", 2, name);
      return false;
    }

    // promise a socket, to the queue
    saffronGlobal.socketCreationQueue[name] = new Promise(
      async (fulfil, reject) => {
        // create the socket
        let { io } = await import("socket.io-client");
        const socket = io(socketUrl + namespace, socketConfig);

        saffronGlobal.sockets[name] = getSocketProxy(socket, name);

        // TODO: consider improving this: only fetch jwt if the demand request has a longer lifetime demand than what we have
        // socket behavior: provides auth token
        socket.on("saffronUser:demandAuthToken", async (data) => {
          let token = false;

          if (store) {
            await store.dispatch("user/refreshJwt");
            token = store.getters["user/tokenType"];
          }

          // noinspection JSCheckFunctionSignatures
          socket.emit("saffronUser:provideAuthToken", token);
        });

        // socket behaviour: receives and tracks own uuid
        socket.on("saffron:setSocketUUID", async (uuid) => {
          // noinspection JSUnresolvedFunction - this exists in our proxy. chillax.
          saffronGlobal.sockets[name].setUUID(uuid);
        });

        // keep providing JWT to backend to keep our authentication
        if (store) {
          watchEffect(() => {
            let token = store.getters["user/token"];
            socket.emit("saffronUser:provideAuthToken", token);
          });
        }

        fulfil(saffronGlobal.sockets[name]);
      }
    );

    // also return this promise
    return saffronGlobal.socketCreationQueue[name];
  };

  // sockets proxy handler: access to sockets - this is our final export
  const socketsGlobalProxyHandler = {
    get(target, prop, receiver) {
      // support vue symbol accessors
      if (typeof prop === "symbol") {
        return target[prop]; // which can be undefined
      }

      // expose a dummy init method, to prepare a default socket if required
      if (prop === "init" || prop === "initialize") {
        return lazyCreateDefaultSocket;
      }

      // expose create socket
      if (prop === "create" || prop === "createSocket") {
        return createSocket;
      }

      // give existing socket / properties if available
      if (target.hasOwnProperty(prop)) {
        return target[prop];
      }

      // catch all - normal object behaviour (undefined)
      if (target.hasOwnProperty(prop)) {
        return target[prop];
      }

      if (target.hasOwnProperty("default")) {
        return target.default[prop];
      }
      return undefined;
    },
  };

  return new Proxy(saffronGlobal.sockets, socketsGlobalProxyHandler);
};

export default (props, storeOverride, options) => {
  let asyncOps;
  let instanceId = utilities.getUniqueNumber();
  let instance = getCurrentInstance();

  // TODO: this is probably not in use, actually.
  if (typeof options === "object" && options && options.mainStoreInstance) {
    console.log(
      "asyncops sockets attempts to touch mainStoreInstance - which will mess up SSR. blocked."
    );
    //  mainStoreInstance = options.mainStoreInstance;
  }

  if (typeof props !== "object" || props === null) {
    props = {};
  }

  let store;

  if (getCurrentInstance()) {
    store = useStore();
  } else {
    // TODO: on ssr this solution causes issue. we need to send requests with user data from store (JWT token), but the global store is module-wide and will cause horrors in SSR
    // try to access the store from the app maybe, somehow
    store = storeOverride;
  }

  if (!store) {
    store = false;
  }

  let authToken = computed(() => {
    if (!store) {
      return "";
    }

    return store.getters["user/token"];
  });

  let authTokenType = computed(() => {
    if (!store) {
      return "";
    }

    return store.getters["user/tokenType"];
  });

  // reactive default request and response adapters, exposed to composition caller
  let defaultRequestAdapter = ref({ value: null });
  let defaultResponseAdapter = ref({ value: null });

  // ready status for async operations. exposed to composition caller
  let asyncOpsReady = ref({ value: false });

  let readyPromise = new Promise((fulfil) => {
    let stop;
    let stopWatching = () => {
      try {
        stop();
      } catch (e) {}
    };
    stop = watchEffect(() => {
      if (asyncOpsReady.value) {
        fulfil(true);
        stopWatching();
      }
    });
  });
  // list of current async tasks. used internally
  let runningAsyncTasks = reactive({});

  let runningAsyncDataRequests = reactive({});

  // are we performing an sync operation right now? exposed to composition caller
  let asyncStatus = computed(() => {
    let loading = false;
    let asyncDataLoading = false;
    let asyncDataClearFinal = true;
    let asyncDataClear1 = true;
    let asyncDataClear2 = true;

    // update loading state, local and global
    Object.keys(runningAsyncTasks).forEach((key) => {
      if (runningAsyncTasks[key]) {
        loading = true;
      }
    });

    // update global state
    globalLoadingInstances[instanceId] = loading;

    // compute anyLoading - is any instance of asyncOps working at the moment?
    let temp = Object.values(globalLoadingInstances || {});
    let anyLoading = Array.isArray(temp) && temp.includes(true);

    // update async data loading
    Object.keys(runningAsyncDataRequests).forEach((key) => {
      if (runningAsyncDataRequests[key]) {
        asyncDataLoading = true;
      }
    });

    /**
     * When async data seems to be ready, cascade through 2 cycles. if it is still ready, declare it as "cleared"
     */
    // when async data is loading -
    watchEffect(() => {
      // if loading - than async data is not clear
      if (asyncDataLoading) {
        asyncDataClearFinal = false;
        asyncDataClear1 = false;
        asyncDataClear2 = false;
      } else {
        // trigger watcher for asyncDataClear1 on next cycle
        nextTick(() => {
          asyncDataClear1 = true;
        });
      }
    });

    // async data was fetched one cycle ago.
    watchEffect(() => {
      if (asyncDataLoading) {
        asyncDataClearFinal = false;
        asyncDataClear1 = false;
        asyncDataClear2 = false;
      } else {
        // trigger watcher for asyncDataClear1 on next cycle
        nextTick(() => {
          asyncDataClear2 = true;
        });
      }
    });

    // async data was fetched two cycles ago
    watchEffect(() => {
      if (asyncDataLoading) {
        asyncDataClearFinal = false;
        asyncDataClear1 = false;
        asyncDataClear2 = false;
      } else {
        // trigger watcher for asyncDataClear1 on next cycle
        nextTick(() => {
          asyncDataClearFinal = true;
        });
      }
    });

    return {
      loading,
      globalLoadingInstances,
      anyLoading,
      asyncDataLoading,
      asyncDataClear: asyncDataClearFinal,
      asyncDataReady: asyncDataClearFinal,
    };
  });

  // our internal service status tracker. when all are ready, asyncOpsReady changes
  let serviceStatus = {
    requestAdapter: false,
    responseAdapter: false,
  };

  // queue to allow us to execure async ops requested, only when we are ready
  let queue = [];

  // method to execute the task queue
  let executeQueue = () => {
    queue.forEach((val) => {
      val();
    });

    queue = [];
  };

  // method to run task if able, or put it in queue to run when able
  let executeWhenAble = function (callback) {
    if (asyncOpsReady.value === true) {
      callback();
    } else {
      queue.push(callback);
    }
  };

  // watch our ready status. runs queue if needed
  watchEffect(() => {
    if (asyncOpsReady.value) {
      executeQueue();
    }
  });

  /**
   * Internal method to check if all services are ready
   * @returns {boolean}
   */
  function areAllServicesReady() {
    let ready = true;

    for (const [key, value] of Object.entries(serviceStatus)) {
      if (!value) {
        ready = false;
      }
    }

    return ready;
  }

  /**
   * Register that one of our async props/functions is ready
   * used to track the ready state of asyncOps
   * @param key
   * @param value
   */
  function updateServiceStatus(key, value) {
    serviceStatus[key] = value;

    if (areAllServicesReady()) {
      asyncOpsReady.value = true;
    }
  }

  /**
   * Setup default adapters. Allows us to fetch and cache them
   */
  function setupDefaultAdapters() {
    getRequestAdapter(props.asyncDataDefaults).then((result) => {
      defaultRequestAdapter.value = result;
      updateServiceStatus("requestAdapter", true);
    });

    getResponseAdapter(props.asyncDataDefaults).then((result) => {
      defaultResponseAdapter.value = result;
      updateServiceStatus("responseAdapter", true);
    });
  }

  /**
   * For a named async task, register that it is running
   * used to track async fetch state
   * @param name
   */
  function registerAsyncTaskStart(name) {
    runningAsyncTasks[name] = true;
  }

  /**
   * For a named async task, register that it is completed
   * used to track async fetch state
   * @param name
   * @param result
   */
  function registerAsyncTaskEnd(name, result) {
    if (typeof runningAsyncTasks[name] !== "undefined") {
      // trigger the reactivity
      runningAsyncTasks[name] = false;
      // dont spam hare, delete what's done
      delete runningAsyncTasks[name];
    }
  }

  const getRequestHash = (target, data = {}, options = {}) => {
    const key = JSON.stringify({ target, data, options });

    // Use a simple hashing algorithm to create a numeric hash
    let hash = 0;
    for (let i = 0; i < key.length; i++) {
      const char = key.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32-bit integer
    }

    return hash.toString();
  };

  /**
   * TODO: refactor / simplify? this uses a lot of "sub functions", but is still long
   * Make an async call that changes our loading state
   * @param target url for call
   * @param data data for call
   * @param options for call. may include these important keys:
   * 1. method (post/get etc, whatever the request adapter supports)
   * 2. requestAdapter, responseAdapter - name of desriable adapters (or default is used)
   * 3. all options are passed to both adapters, and they can do whatever they want with it
   * @returns {Promise<*>}
   */
  let asyncCall = async (target, data = {}, incomingOptions = {}) => {
    let options = { ...incomingOptions };
    // should we try to do some UI stuff here?
    let showErrorUi, showSpinner, successNotification, method, adapters;

    // ~ 1/50,000 collision chance
    const localGetRequestHash = () => {
      return getRequestHash(target, data, incomingOptions);
    };

    const getRequestAdapterForRequest = (name) => {
      return new Promise(async (resolve, reject) => {
        if (typeof name !== "string") {
          return resolve(defaultRequestAdapter.value);
        }

        // we need custom adapter
        let adapter = await getRequestAdapter(name);
        resolve(adapter);
      });
    };
    const getResponseAdapterForRequest = (name) => {
      return new Promise(async (resolve, reject) => {
        if (typeof name !== "string") {
          return resolve(defaultResponseAdapter.value);
        }

        // we need custom adapter
        let adapter = await getResponseAdapter(name);
        resolve(adapter);
      });
    };
    const getRequestAdapters = async (reqAdapterName, resAdapterName) => {
      return await Promise.all([
        getRequestAdapterForRequest(reqAdapterName || "default"),
        getResponseAdapterForRequest(resAdapterName || "default"),
      ]);
    };
    const runAndAppendCaptchaCode = async () => {
      let { executeCaptcha } = useRecaptcha();
      let captchaResult = await executeCaptcha();

      if (captchaResult.isError) {
        debug(
          "error appending captcha challenge to asyncCall - recaptcha composition failed to get token",
          { captchaResult }
        );
        return false;
      }

      if (data === null || typeof data === "undefined") {
        data = {};
      }

      if (typeof data !== "object") {
        // error - we cant automatically add captcha code
        debug(
          "error appending captcha challenge to asyncCall - data object must be undefined, null, or object",
          { data }
        );
        return false;
      }

      data.securityChallenge = captchaResult.token;
      return true;
    };
    const populateUiOptions = () => {
      showErrorUi = false;
      showSpinner = false;
      successNotification = false;

      // short form to show ui
      if (options?.withUi) {
        showErrorUi = true;
        showSpinner = true;
        successNotification =
          typeof options.withUi === "string" ? options.withUi : true;
      }

      //detailed error behaviour
      if (options.errorUi) {
        showErrorUi = options.errorUi;
      }

      if (options.showSpinner) {
        showSpinner = options.showSpinner;
      }

      if (options.successNotification) {
        successNotification = options.successNotification;
      }
    };
    const getRequestResultFromCache = (key = false) => {
      if (!store) {
        return undefined;
      }
      if (!key) {
        key = localGetRequestHash();
      }
      return store.getters["asyncData/getFromCache"](key);
    };
    const cacheRequestResult = async (result, key, ttlMs = false) => {
      if (!store) {
        return false;
      }

      if (!ttlMs) {
        ttlMs = options.cache.ttl;
      }
      store.commit("asyncData/setToCache", { key, data: result, ttlMs });
      return true;
    };
    const executeAndParseRequest = async () => {
      let requestKey = localGetRequestHash();

      let result;
      // method to use adapters to make the actual call
      const executeRequest = async () => {
        // execute the request using the request adapter
        let rawResponse = await adapters[0][options.method](
          target,
          data,
          options
        );

        // parse the response using the response adapter
        return await adapters[1].parse(rawResponse, options);
      };

      const doPreRequestUi = () => {
        if (showSpinner) {
          showGlobalSpinner(showSpinner);
        }
      };

      const doAfterRequestUi = () => {
        if (showSpinner) {
          hideGlobalSpinner();
        }

        if (showErrorUi && result.isError) {
          showErrorNotification(result, showErrorUi);
        }

        if (successNotification && !result.isError) {
          showSuccessNotification(successNotification);
        }
      };

      const cleanupRequest = async (result) => {
        // ui cleanup (spinners/notifications)
        doAfterRequestUi();

        // request is resolved, clear from store pending state
        if (store) {
          store.commit("asyncData/setRequestAsComplete", requestKey);
        }

        // store cleanup - cache result
        if (store && options.cache.enable) {
          store.commit("asyncData/setRequestAsComplete", requestKey);
          await cacheRequestResult(result, requestKey);
        }

        return result;
      };

      const executeRequestAndCleanup = async () => {
        result = await executeRequest();
        await cleanupRequest(result);
        return result;
      };

      // server from cache - start
      let cachedValue = getRequestResultFromCache(requestKey);

      if (store && options.cache.enable && cachedValue) {
        return cachedValue;
      }

      // serve from cache - end
      doPreRequestUi();

      // if we have no sore, we execute, cache, cleanup and return
      if (!store) {
        return executeRequestAndCleanup();
      }

      // we have a store, so we can check if the request is already pending. If it does, we can just use that instead of making another call
      // if the request is already in progress, await for it's promis
      let requestInProgress =
        store.getters["asyncData/requestInProgress"](requestKey);

      if (requestInProgress) {
        // wait for the previous promise then do ui/cache when done
        result = await requestInProgress.promise;
        return cleanupRequest(result);
      }

      // the request is not pending - run it
      const requestPromise = executeRequest();

      store.commit("asyncData/setRequestAsInProgress", {
        key: requestKey,
        promise: requestPromise,
      });

      result = await requestPromise;
      return await cleanupRequest(result);
    };
    const runAndAppendCaptchaIfRequired = async () => {
      if (options && typeof options === "object" && options.useRecaptcha) {
        await runAndAppendCaptchaCode();
      }
    };
    const overloadLocaleByStore = () => {
      if (
        store &&
        typeof options === "object" &&
        !options.hasOwnProperty("locale")
      ) {
        options.locale = store.getters["locale/slug"];
      }
    };
    const overloadAuth = () => {
      if (!options.authorization) {
        options.authorization = {
          tokenType: unref(authTokenType),
          token: unref(authToken),
        };
      }
    };
    const overloadCachingConfig = () => {
      let cacheOptions = {
        enable:
          options.method === "get" &&
          config.asyncData.cache.cacheGetRequestsByDefault,
        ttl: config.asyncData.cache.defaultCacheTtlMs,
        key: localGetRequestHash(),
      };

      if (typeof options.cache === "boolean") {
        cacheOptions.enable = options.cache;
      }

      if (typeof options.cache === "number") {
        cacheOptions.ttl = options.cache;
      }

      if (options.cache && typeof options.cache === "object") {
        cacheOptions = {
          ...cacheOptions,
          ...options.cache,
        };
      }

      options.cache = cacheOptions;
    };
    const enforceOptionsIntegrity = () => {
      // overload locale
      overloadLocaleByStore();

      // enforce method
      if (!options.method) {
        options.method = "get";
      }

      // overload auth
      overloadAuth();

      // default ui options
      populateUiOptions();

      overloadCachingConfig();
    };

    // log in our state that the async task is running
    registerAsyncTaskStart(target);

    // enforce options integrity so they are safe to work with and have defaults
    enforceOptionsIntegrity();

    // overload recaptcha if needed (overloads data)
    await runAndAppendCaptchaIfRequired();

    // get the adapters. This may trigger a call to the adapters
    adapters = await getRequestAdapters(
      options.requestAdapter,
      options.responseAdapter
    );

    let result = await executeAndParseRequest();

    // if we are doing a post,delete,update - because we might have changed data
    if (
      options.method != "get" &&
      store &&
      config.asyncData.cache.clearCacheOnPost
    ) {
      clearAllRequestDataFromCache();
    }

    registerAsyncTaskEnd(target, result);

    if (result && typeof result === "object") {
      return _.cloneDeep(result);
    }

    return result;
  };

  const showGlobalSpinner = (text) => {
    if (!instance) {
      return false;
    }
    if (typeof text !== "string") {
      text = "";
    }

    instance.ctx.$s.ui.globalSpinner.show(text);
  };

  const hideGlobalSpinner = () => {
    if (!instance) {
      return false;
    }
    instance.ctx.$s.ui.globalSpinner.hide();
  };

  const showErrorNotification = (result, customText = false) => {
    let getErrorTextFromResponseAndArgument = (result, showErrorUi) => {
      if (typeof showErrorUi === "string") {
        return showErrorUi;
      }
      let autoErrors = [403, "403", 404, "404", 422, "422", 500, "500"];

      if (autoErrors.includes(result.code)) {
        return `core.genericError${result.code}`;
      }

      return "core.genericError500";
    };

    if (result.isError && instance) {
      try {
        instance.ctx.$s.ui.notification(
          getErrorTextFromResponseAndArgument(result, customText),
          "error"
        );
      } catch (e) {
        warn(
          "async ops tried to show error notification, but there was an exception",
          e
        );
      }
    }
  };

  const showSuccessNotification = (customText = false) => {
    let text =
      typeof customText === "string" ? customText : "core.successGeneric";
    if (!instance) {
      return false;
    }
    try {
      instance.ctx.$s.ui.notification(text, "success");
    } catch (e) {
      warn(
        "async ops tried to show a success notification, but there was an exception",
        e
      );
    }
  };

  /**
   * TODO: make this awaitable for SSR
   * Based on component's asyncData property, fetch it's async data
   * @param config
   * @param component the component that will recieve the data
   */
  let fetchAsyncData = async (config = null, component) => {
    let asyncDataConfig = config !== null ? config : component.asyncData;
    let targets = {};

    // helper function to enforce async request config integrity
    function getSafeRequestConfig(requestConfig) {
      let val = null;

      // support string key (convert to object
      if (typeof requestConfig === "string") {
        val = {
          target: requestConfig,
          data: {},
          options: {},
        };
      }

      if (typeof requestConfig === "object" && requestConfig !== null) {
        val = Object.assign({}, requestConfig);
      }

      if (typeof val !== "object" || val === null) {
        debug(
          'Bad argument for fetchAsyncData, should be string or object with a "target" property',
          2,
          requestConfig
        );
        return false;
      }

      // enforce integrity
      if (typeof val.target === "undefined") {
        debug(
          'Bad argument for fetchAsyncData, should be string or object with a "target" property',
          2,
          requestConfig
        );
        return false;
      }

      // support function
      if (typeof val.data === "function") {
        val.data = val.data();
      }

      // enforce value for data as object
      if (typeof val.data === "object" && val.data !== null) {
        val.data = _.merge({}, val.data);
      }

      if (typeof val.data !== "object" || val.data === null) {
        val.data = {};
      }

      // enforce value for options as object
      if (typeof val.options === "object" && val.options !== null) {
        val.options = _.merge({}, val.options);
      }

      if (typeof val.options !== "object" || val.options === null) {
        val.options = {};
      }

      // check for a store key
      if (typeof val.storeKey !== "string") {
        val.storeKey = false;
      }

      if (typeof val.shouldFetch === "undefined") {
        val.shouldFetch = true;
      }

      return val;
    }

    // create valid well formatted call arguments
    Object.keys(asyncDataConfig).forEach((key) => {
      let original = asyncDataConfig[key],
        safeConfig;

      safeConfig = getSafeRequestConfig(original);

      if (safeConfig) {
        targets[key] = safeConfig;
      }
    });

    // flash the request queue
    Object.keys(runningAsyncDataRequests).forEach(function (key) {
      delete runningAsyncDataRequests[key];
    });

    let fetchPromises = [];

    // fetch all the data
    for (const key of Object.keys(targets)) {
      let target = targets[key],
        requestKey;

      // skip if the target specified a condition
      if (
        target.shouldFetch === false ||
        (typeof target.shouldFetch === "function" && !target.shouldFetch())
      ) {
        return true;
      }

      requestKey = key + utilities.getUniqueNumber();

      // log that the request is running
      runningAsyncDataRequests[requestKey] = true;

      // run the request
      let executeCallback = async () => {
        const logOperationComplete = () => {
          // request is now finished
          delete runningAsyncDataRequests[requestKey];

          // log that the request is completed
          delete runningAsyncDataRequests[requestKey];
        };

        const handleSuccessResponse = async (target, result) => {
          let isRaw = target.options.responseRaw;

          if (
            target.options.dataMutator &&
            typeof target.options.dataMutator === "function"
          ) {
            // do not mutate the original - by making a complete copy we can cotinue
            result = _.cloneDeep(result);
            result.data = await target.options.dataMutator(result.data, result);
          }

          // TODO: save this in store and return a store getter for SSR
          if (target.storeKey && store) {
            store.commit("asyncData/generic", {
              key: target.storeKey,
              value: isRaw ? result : result.data,
            });
          } else {
            component[key] = isRaw ? result : result.data;
          }
        };

        let targetUrl = target.target;

        if (typeof target.target === "function") {
          targetUrl = await target.target({
            component,
            store,
            asyncOps,
            asyncDataConfig,
          });
        }

        let result = await asyncCall(targetUrl, target.data, target.options);

        // for raw response - assign it, delete the request and we are done
        if (target.options.responseRaw) {
          component[key] = result;
          delete runningAsyncDataRequests[requestKey];
          return;
        }

        if (!result.isError) {
          await handleSuccessResponse(target, result);
          logOperationComplete();
          return;
        }

        let errorHandler = target?.options?.errorHandler;
        // if no error handler - we are done here
        if (!errorHandler) {
          logOperationComplete();
          return false;
        }

        if (errorHandler === "404" || errorHandler === 404) {
          try {
            instance.proxy.$root.$router.push({ name: "404" });
          } catch (e) {
            console.log(e);
            warn(
              "AsyncOperations: error handler for async data activated: type 404. Failed to push route. It is possible that this was instantiated without an instance (outside of setup context)",
              { instance }
            );
          }
          logOperationComplete();
          return false;
        }

        // custom error handler - a function
        if (typeof errorHandler === "function") {
          if (await target.options.errorHandler(result)) {
            await handleSuccessResponse(target, result);
          }
          logOperationComplete();
          return false;
        }

        // catch all - make sure we cleanup even if our code isnt perfect
        logOperationComplete();
        return false;
      };
      await readyPromise;
      fetchPromises.push(
        new Promise((fulfil) => {
          executeCallback().then(fulfil).catch(fulfil);
        })
      );
    }

    try {
      await Promise.all(fetchPromises);
    } catch (e) {
      // we ignore errors on purpose because this has to return without exceptions
    }

    return true;
  };

  // use async call to implement asyncData obtaining via config
  setupDefaultAdapters();

  let initializedSocketsComposition = socketsComposition(props, options);

  // TODO: improve this. maybe there are more things to cleantup
  let cleanUp = () => {
    try {
      delete globalLoadingInstances[instanceId];
    } catch (e) {}
  };

  if (instance) {
    try {
      onBeforeUnmount(() => {
        cleanUp();
      });
    } catch (e) {}
  }

  let clearRequestDataFromCache = (idOrTarget, data, options) => {
    if (!store) {
      return false;
    }

    let key1 = idOrTarget;
    let key2 = getRequestHash(idOrTarget, data, options);
    let res1 = store.commit("asyncData/clearRequestDataFromCache", key1);
    let res2 = store.commit("asyncData/clearRequestDataFromCache", key2);

    return res1 || res2;
  };

  let clearAllRequestDataFromCache = () => {
    if (!store) {
      return false;
    }

    return store.commit("asyncData/clearRequestDataCache");
  };

  let purgeExpiredRequestDataCache = () => {
    if (!store) {
      return false;
    }

    return store.commit("asyncData/purgeExpiredRequestDataCache");
  };

  // todo: consider: using proxy to allow execution of asyncOps
  asyncOps = reactive({
    socket: initializedSocketsComposition,
    asyncOpsReady,
    getRequestAdapter,
    getResponseAdapter,
    requestAdapter: defaultRequestAdapter,
    responseAdapter: defaultResponseAdapter,
    asyncStatus,
    asyncCall,
    call: asyncCall,
    fetchAsyncData,
    showErrorNotification,
    clearRequestDataFromCache,
    clearAllRequestDataFromCache,
    purgeExpiredRequestDataCache,
  });

  return {
    asyncOpsReady,
    getRequestAdapter,
    getResponseAdapter,
    requestAdapter: defaultRequestAdapter,
    responseAdapter: defaultResponseAdapter,
    asyncStatus,
    asyncCall,
    fetchAsyncData,
    asyncOps,
    socket: initializedSocketsComposition,
    getSocketComposition: socketsComposition,
    showErrorNotification,
    clearRequestDataFromCache,
    clearAllRequestDataFromCache,
    purgeExpiredRequestDataCache,
  };
};
