let messages = {
  sg: {
    pageTitle: "מדריך סגנונות",
    tabs: {
      margins: "מרווחים",
      forms: "טפסים",
      colors: "צבעים",
      typography: "טיפוגרפיה",
      buttons: "כפתורים",
      badges: "תגיות",
      spinners: "ספינרים",
      spinnersOverlay: "ספינרים - כיסוי צף",
      icons: "איקונים",
      cards: "כרטיסיות",
      panels: "פאנלים",
      modals: "מודאלים",
      alerts: "התרעות",
      breadcrumbs: "פירורי לחם",
      notifications: "הודעות",
      pagination: "עימוד",
      tables: "טבלאות",
      tooltips: "טולטיפים",
      containers: "קונטיינרים",
      sideNav: "ניווט צד",
    },
    colorGroups: {
      base: "בסיסיים",
      complimentary: "משלימים",
      "gray-scale": "גווני אפור (gray)",
      ui: "ממשק",
      lead: "מוביל (lead)",
      alt: "משני (alt)",
      complimentaryLead:"משלים ראשי (comp lead)",
      complimentaryAlt:"משלים משני (comp alt)",
      other: "אחר",
      background: "רקע (bg)",
    },
    margins: {
      width: "רוחב",
      xs: "מינימאלי",
      s: "קטן",
      m: "רגיל",
      l: "גדול",
      xl: "",
      "2xl": "2X אקסטרא לארג'",
      max: "מקסימאלי",
    },
    radius: {
      title: "גבול מעגלי",
      radius: "רדיוס",
      s: "קטן",
      m: "בינוני/רגיל",
      l: "גדול",
      round: "מעוגל",
    },
    containers: {
      title: "קונטיינרים",
      explain:
        "קופסאות תוכן ברוחב סטנדרטי. התוכן לעולם אינו נוגע בפינות. הרווחים מהצדדים -לא- מצטברים כאשר מיכלים נמצאים אחד בתוך השני. בהמחשה, הרווחים הללו מודגשים. ניתן לראות למשל כיצד טקסט זה מיושר לכפתורים למעלה (משום ששניהם בתוך מיכל בגודל L)",
      container: "קונטיינר",
      xs: "קטן מאוד",
      s: "קטן",
      m: "רגיל",
      l: "גדול",
      gutter: "מרווח צד",
    },
  },
};

export default messages;
