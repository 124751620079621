(
  function(document, tag) {
    var script = document.createElement(tag);
    var element = document.getElementsByTagName('body')[0];
    script.src = 'https://acsbap.com/apps/app/assets/js/acsb.js';
    script.async = true;
    script.defer = true;
    (typeof element === 'undefined' ? document.getElementsByTagName('html')[0] : element).appendChild(script);
    script.onload = function () {
      acsbJS.init({
        statementLink:
          "https://drive.google.com/file/d/1fmff9Skj3UMP7Eo3c2_EhjefrruwDgMY/view?usp=sharing",
        feedbackLink: "",
        footerHtml: "",
        hideMobile: false,
        hideTrigger: false,
        language: "he",
        position: "left",
        leadColor: "#2c9e96",
        triggerColor: "#2c9e96",
        triggerRadius: "5px",
        triggerPositionX: "left",
        triggerPositionY: "top",
        triggerIcon: "default",
        triggerSize: "small",
        triggerOffsetX: 20,
        triggerOffsetY: 11,
        mobile: {
          triggerSize: "small",
          triggerPositionX: "right",
          triggerPositionY: "bottom",
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: "50%",
        },
      });
    };
  }(document, 'script'));