export default ({ store }) => {
  return {

    fields: {
      fullName: {
        name: "fullName",
        type: "text",
        label: "ls.founder.fields.fullName",
      },
      originCountry: {
        name: "originCountry",
        type: "text",
        label: "ls.founder.fields.originCountry",
      },
      birthYear: {
        name: "birthYear",
        type: "text",
        label: "ls.founder.fields.birthYear",
      },
      deathYear: {
        name: "deathYear",
        type: "text",
        label: "ls.founder.fields.deathYear",
      },
      description: {
        name: "description",
        type: "Editor",
        label: "ls.founder.fields.description",
      },
      youtubeVideos: {
        name: "youtubeVideos",
        type: "repeatable",
        label: "ls.founder.fields.youtubeVideos.videosLabel",
        fields: {
          youtubeId: {
            name: "youtubeId",
            label: "ls.founder.fields.youtubeVideos.youtubeIdLabel",
            type: "youtubeVideoId",
            validation: {
              required: {},
            },
          },
          title: {
            name: "title",
            label: "ls.founder.fields.youtubeVideos.titleLabel",
            type: "textarea",
            maxCharacters: 200,
            validation: {
              maxLength: {
                params: {
                  max: 200,
                }
              }
            }
          },
        },
      },
    },
  };
};
